@font-face {
  font-family: NearFont;
  src: url(../fonts/389947_6_0.eot);
  src: url(../fonts/389947_6_0.eot?#iefix) format("embedded-opentype"), url(../fonts/389947_6_0.woff2) format("woff2"), url(../fonts/389947_6_0.woff) format("woff"), url(../fonts/389947_6_0.ttf) format("truetype");
  font-weight: 500;
  font-style: normal
}

// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.strong{
  font-weight: 700;
}

.near-font{
  font-family: NearFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.c-sidebar-minimized .sidebar-title{
  font-size: x-small;
}
.sidebar-title {
  text-align: center;
  text-decoration: none;
  transition-timing-function: ease;
}

.sidebar-title-small {
  flex: 0 0 96px;
  color: black;
}

aside {
  animation: notify ease-in-out 10s;
  background-color: var(--shadow);
  border-radius: 5px;
  bottom: 3em;
  font-size: 0.8em;
  margin: 1em;
  padding: 1em;
  position: fixed;
  transform: translateY(10em);
  right: 0;
}

aside footer {
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  margin-top: 0.5em;
}

aside footer *:first-child {
  color: var(--success);
}

aside footer *:last-child {
  color: var(--gray);
}

@keyframes notify {
  0% {
    transform: translateY(10em)
  }
  5% {
    transform: translateY(0)
  }
  95% {
    transform: translateY(0)
  }
  100% {
    transform: translateY(10em)
  }
}
